/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from 'react';
import { useFrame } from '@react-three/fiber'; // Corrected import
import { useGLTF, useAnimations, Bounds } from '@react-three/drei';


export function Model(props) {
  const group = useRef(); 
  const { nodes, materials, animations } = useGLTF('/uploads_files_3046034_cajita.glb');
  const { actions } = useAnimations(animations, group);
  useEffect(() => {
    
    // Play all animations (if you know specific names, you can play those specifically)
    Object.values(actions).forEach(action => action.play());

    // If you want to handle stopping, looping, or other properties, you can configure that here
    return () => {
      Object.values(actions).forEach(action => action.stop());
    };
  }, [actions]); // Re-run if actions change

  useFrame(() => {
    if (group.current) {
        group.current.rotation.y += 0.001; // Adjust rotation speed here
    }
  });
  
  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[Math.PI, -0.175, Math.PI]}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cubo.geometry}
        material={materials['Material.004']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cubo001.geometry}
        material={materials['Material.001']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cubo002.geometry}
        material={materials['Material.002']}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cono.geometry}
        material={materials['Material.003']}
      />
      </group>
    </group>
  )
}

useGLTF.preload('/uploads_files_3046034_cajita.glb')